import React from 'react'
import 'src/components/header/header.scss'
import Navigation from 'src/components/navigation/navigation'

const Header = ({ siteTitle }) => (
  <>
    <div className="Header__spacer" />
    <header className="Header">
      <div className="Header__inner">
        <Navigation />
      </div>
    </header>
  </>
)

export default Header
