import React from 'react'
import classNames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import nav from 'src/components/navigation/navigation.module.scss'
import mobileNav from 'src/components/navigation/mobileNavigation.module.scss'

import Logo from 'src/components/logo/logo'

class Navigation extends React.Component {
  state = {
    isMenuOpen: false,
  }
  constructor(props) {
    super(props)
    this.navRef = React.createRef()
    this.headerOffset = 65
  }

  handleOnClick = e => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  renderNavLinkSeparator() {
    return (
      <span className="Navigation__link-separator hide-md-down">&#9670;</span>
    )
  }

  render() {
    const { siteTitle, isSmall } = this.props
    const { isMenuOpen } = this.state
    return (
      <>
        <div>
          <div
            className={classNames({
              [mobileNav.overlay]: isMenuOpen,
            })}
            onClick={this.handleOnClick}
          />
          <nav
            className={classNames({
              [mobileNav.navBar]: true,
              [mobileNav.isOpen]: isMenuOpen,
            })}
            ref={this.navRef}
          >
            <button className={mobileNav.close} onClick={this.handleOnClick} />
            <AnchorLink
              href="#our-story"
              className={mobileNav.link}
              offset={this.headerOffset}
              onClick={this.handleOnClick}
            >
              Our Story
            </AnchorLink>
            <AnchorLink
              href="#gift-registry"
              className={mobileNav.link}
              offset={this.headerOffset}
              onClick={this.handleOnClick}
            >
              Gift Registry
            </AnchorLink>
            <AnchorLink
              href="#event-details"
              className={mobileNav.link}
              offset={this.headerOffset}
              onClick={this.handleOnClick}
            >
              Event Details
            </AnchorLink>
            <AnchorLink
              href="#things-to-do"
              className={mobileNav.link}
              offset={this.headerOffset}
              onClick={this.handleOnClick}
            >
              Things To Do
            </AnchorLink>
          </nav>

          <div className={mobileNav.buttonWrapper}>
            <AnchorLink href="#top" offset={this.headerOffset}>
              <Logo isSmall />
            </AnchorLink>
            <button className={mobileNav.button} onClick={this.handleOnClick}>
              <span className={mobileNav.bar} />
              <span className={mobileNav.bar} />
              <span className={mobileNav.bar} />
            </button>
          </div>
          <nav className={nav.navbar} ref={this.navRef}>
            <AnchorLink
              href="#our-story"
              className={nav.link}
              offset={this.headerOffset}
            >
              Our Story
            </AnchorLink>

            <AnchorLink
              href="#gift-registry"
              className={nav.link}
              offset={this.headerOffset}
            >
              Gift Registry
            </AnchorLink>
            <AnchorLink
              className={nav.logoLink}
              href="#top"
              offset={this.headerOffset}
            >
              <Logo
                className={classNames({
                  [nav.logo]: true,
                  'hide-md-up': isSmall,
                  'hide-md-down': !isSmall,
                })}
              />
              <h1 className="screen-reader-text">{siteTitle}</h1>
            </AnchorLink>
            <AnchorLink
              href="#event-details"
              className={nav.link}
              offset={this.headerOffset}
            >
              Event Details
            </AnchorLink>
            <AnchorLink
              href="#things-to-do"
              className={nav.link}
              offset={this.headerOffset}
              onClick={this.handleOnClick}
            >
              Things To Do
            </AnchorLink>
          </nav>
        </div>
      </>
    )
  }
}

export default Navigation
